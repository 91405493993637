import { Button, TextField, Typography, Container, Box } from '@mui/material'
import React from 'react'

const Settings: React.FC<{ setHeaderLogo: any }> = ({ setHeaderLogo }) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const headerLogo = formData.get('headerLogoUrl') as string
        sessionStorage.setItem(
            'layoutUpdate',
            JSON.stringify({ headerLogo: headerLogo })
        )
        setHeaderLogo(headerLogo)
    }

    return (
        <Container maxWidth="md">
            <Box className="settings-form">
                <Typography variant="h4">Settings</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Header Logo Url"
                        name="headerLogoUrl"
                        variant="standard"
                        fullWidth
                        helperText="Rectangle logos with the name included work best"
                    />
                    <Box className="submit-button-container">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            SAVE
                        </Button>
                    </Box>
                </form>
            </Box>
            <Box className="submit-button-container">
                <Button
                    onClick={() =>
                        window.open(
                            process.env.REACT_APP_MARKETPLACE_LINK,
                            '_blank',
                            `width=800,height=700,left=100,top=100,scrollbars,resizable`
                        )
                    }
                    variant="contained"
                    color="primary"
                >
                    TEST EMP
                </Button>
            </Box>
        </Container>
    )
}

export default Settings
